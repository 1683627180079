export const appConfig = {
    header: {
        miniOffset: -55,
    },
    toc: {
        acordeonizeHorizontal: true,
        acordeonizeVertical: false,

        horizontalLinksExpandPanel: true,
        verticalLinksExpandPanel: false,
    }
}
