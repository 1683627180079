// noinspection RegExpRedundantEscape

import axios from 'axios';
import slugify from 'slugify';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import 'style-scoped';
import {appConfig} from './config';

// https://github.com/laravel/framework/blob/5299c22321c0f1ea8ff770b84a6c6469c4d6edec/src/Illuminate/Translation/MessageSelector.php#L15
window.pluralize              = function(text, number, variables) {
  function extract(segments, number) {
    for (const part of segments) {
      const line = extractFromString(part, number);

      if (line !== null) {
        return line;
      }
    }
  }

  function extractFromString(part, number) {
    const matches = part.match(/^[\{\[]([^\[\]\{\}]*)[\}\]](.*)/s);

    if (matches === null || matches.length !== 3) {
      return null;
    }

    const condition = matches[1];

    const value = matches[2];

    if (condition.includes(',')) {
      const [from, to] = condition.split(',', 2);

      if (to === '*' && number >= from) {
        return value;
      } else if (from === '*' && number <= to) {
        return value;
      } else if (number >= from && number <= to) {
        return value;
      }
    }

    return condition === number ? value : null;
  }

  function ucfirst(string) {
    return (string.toString().charAt(0).toUpperCase() + string.toString().slice(1));
  }

  function replace(line, replace) {
    if (replace.length === 0) {
      return line;
    }

    const shouldReplace = {};

    for (let [key, value] of Object.entries(replace)) {
      shouldReplace[':' + ucfirst(key ?? '')] = ucfirst(value ?? '');
      shouldReplace[':' + key.toUpperCase()]  = value
        .toString()
        .toUpperCase();
      shouldReplace[':' + key]                = value;
    }

    Object.entries(shouldReplace).forEach(([key, value]) => {
      line = line.replaceAll(key, value);
    });

    return line;
  }

  function stripConditions(segments) {
    return segments.map((part) => part.replace(/^[\{\[]([^\[\]\{\}]*)[\}\]]/, ''));
  }

  let segments = text.split('|');

  const value = extract(segments, number);

  if (value !== null && value !== undefined) {
    return replace(value.trim(), variables);
  }

  segments = stripConditions(segments);

  return replace(segments.length > 1 && number > 1 ? segments[1] : segments[0], variables);
};
window._q                     = (target, selector) => {
  return selector ? target.querySelector(selector) : document.querySelector(target);
};
window._qa                    = (target, selector) => {
  return selector ? target.querySelectorAll(selector) : document.querySelectorAll(target);
};
window.adjustFont             = delta => {
  let html = document.documentElement, size = parseInt(getComputedStyle(html).getPropertyValue('--font-size'), 10);
  size += delta;
  size     = Math.max(size, 14);
  size     = Math.min(size, 19);
  html.style.setProperty('--font-size', size + 'px');
};
window.axios                  = axios;
window.domReady               = f => {
  window.addEventListener('DOMContentLoaded', (e) => f(e));
};
window.durationToMilliseconds = duration => {
  switch (typeof duration) {
    case 'undefined':
      duration = 600;
      break;
    case 'number':
      break;
    case 'function':
      duration = duration();
      break;
    case 'string':
      switch (duration) {
        case 'fast':
          duration = 200;
          break;
        case 'slow':
          duration = 1000;
          break;
        default:
          duration = 500;
      }
      break;
    default:
      duration = 500;
      break;
  }

  return duration;
};
window.easeOutPow             = (x, pow) => 1 - Math.pow(1 - x, pow);
window.easeOutQuint           = x => window.easeOutPow(x, 5);
window.FingerprintJS          = FingerprintJS;
window.slugger                = (str, opts) => slugify(str, {
  ...{
    replacement: '-', remove: /[*+~.()'"!:@?]/g, lower: true
  }, ...opts
});
window.sleep                  = ms => new Promise(r => setTimeout(r, ms));
window.dataLayer              = window.dataLayer || [];
window.appConfig              = appConfig;

Element.prototype.scrollToSmoothly       = function() {
  const offset = window.scrollY + this.getBoundingClientRect().top - _q('header').clientHeight - 70;
  if (typeof arguments[0] === 'function') {
    setTimeout(() => arguments[0](), 500);
  }

  window.scrollTo({
                    top: offset, behavior: 'smooth'
                  });
};
Element.prototype.siblings               = function(skipMe) {
  let curr = this.parentNode.firstChild, siblings = [];

  for (; curr; curr = curr.nextSibling) {
    if (curr.nodeType === 1 && (!skipMe || curr !== this)) {
      siblings.push(curr);
    }
  }

  return siblings;
};
Element.prototype.isInViewport           = function() {
  const rect         = this.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  return (rect.top <= windowHeight || rect.bottom <= windowHeight) && (rect.top >= 0 || rect.bottom >= 0);
};
Element.prototype.maxScrollTop           = function() {
  const list = this.parentElement;

  /** @this HTMLElement */
  return Math.min(this.offsetTop - list.offsetTop, list.scrollHeight - list.clientHeight);
};
Element.prototype.nextUntil              = function(selector, filter) {
  // See https://vanillajstoolkit.com/helpers/nextuntil/

  // Start from current element and get the next sibling element
  let elem = this.nextElementSibling;

  // Setup siblings array
  let siblings = [];

  // As long as a sibling exists
  while (elem) {

    // If we've reached our match, bail
    if (elem.matches(selector)) break;

    // If filtering by a selector, check if the sibling matches
    if (filter && !elem.matches(filter)) {
      elem = elem.nextElementSibling;
      continue;
    }

    // Otherwise, push it to the siblings array
    siblings.push(elem);

    // Get the next sibling element
    elem = elem.nextElementSibling;

  }

  return siblings;
};
Element.prototype.scrollIntoViewSmoothly = function(options) {
  options = {
    ...{
      behavior: 'smooth', block: 'nearest', inline: 'nearest', duration: 500
    }, ...(options ?? {})
  };

  this.scrollIntoView(options);
};
Element.prototype.scrollInListSmoothly   = async function() {
  /** @this HTMLElement */
  const list     = this.parentElement;
  /** @this HTMLElement */
  const interval = 5;

  let params     = Array.prototype.slice.call(arguments);
  const callback = (typeof params[0] === 'function') ? params.shift() : null;
  const duration = window.durationToMilliseconds(params.shift());

  let progress           = 0;
  const initialScrollTop = list.scrollTop;
  const distance         = this.maxScrollTop() - initialScrollTop;

  if (Math.abs(distance) > 200) {

    const steps = duration / interval;

    for (let i = 0; i <= steps; i++) {
      progress       = Math.round(distance * easeOutQuint(i / steps));
      list.scrollTop = initialScrollTop + progress;

      // distance and progress have the same sign
      if (Math.abs(distance - progress) < 10) {
        break;
      }

      await window.sleep(interval);
    }
  }

  if (callback !== null) {
    // noinspection JSValidateTypes
    callback();
  }
};
Number.prototype.toLocale                = function(locale, digits, percent) {
  return this.toLocaleString(locale, {
    minimumFractionDigits: digits, maximumFractionDigits: digits, style: percent ? 'percent' : 'decimal'
  });
};
Number.prototype.toCurrency              = function(locale, currency, digits) {
  return this.toLocaleString(locale, {
    style: 'currency', currency: currency, currencyDisplay: 'code', minimumFractionDigits: digits, maximumFractionDigits: digits
  });
};
String.prototype.limit                   = function(length, keepWords) {

  if (this.trim().length < length) {
    return this.trim();
  }

  if (keepWords) {
    const words = this.trim().split(' ');
    let text    = '';
    while (text.length < 16 && words.length > 0) {
      text += words.shift() + ' ';
    }

    return text.trim() + '...';
  } else {
    return this.trim().length > length ? this.trim().substring(0, length - 3) + '...' : this.trim();
  }

};
String.prototype.capitalize              = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
String.prototype.supplant                = function(o) {
  return this.replace(/{([^{}]*)}/g, function(a, b) {
    const r = o[b];
    return typeof r === 'string' || typeof r === 'number' ? r : a;
  });
};
String.prototype.hashCode                = function() {
  let hash = 0,
      i, chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr  = this.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
